var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news news__page"},[_c('section',{staticClass:"section section__city__news"},[_c('div',{staticClass:"news__inner"},[_c('div',{staticClass:"row"},[(_vm.showAside)?_c('div',{staticClass:"col-9 news__side"},[_c('h2',[_vm._v("Все новости города")]),_c('ul',{staticClass:"_items_list news__city-list"},_vm._l((_vm.newsMayor),function(item,index){return _c('li',{key:index,staticClass:"_item"},[_c('div',{staticClass:"img"},[_c('router-link',{staticClass:"img__container",attrs:{"to":{
                    name: 'news_item',
                    params: { id: item.id, url: item.link },
                  }}},[_c('img',{attrs:{"src":_vm._f("image")(item.head_img,_vm.$store.state.api),"alt":_vm._f("image_alt")(item.head_img)}})])],1),_c('div',{staticClass:"news__mayor__header _item__header"},[(item.category && item.category.title)?_c('a',{staticClass:"_item__category news__mayor__color",attrs:{"href":"#"}},[_vm._v("\n                  "+_vm._s(item.category.title)+"\n                ")]):_vm._e(),(item.date_publication)?_c('div',{staticClass:"_item__time news__mayor__color"},[_c('time',{attrs:{"datetime":_vm._f("robotDate")(item.date_publication)}},[_vm._v("\n                    "+_vm._s(_vm._f("humanDateRu")(item.date_publication))+"\n                  ")])]):_vm._e()]),_c('router-link',{staticClass:"_item__text",attrs:{"to":{
                  name: 'news_item',
                  params: { id: item.id, url: item.link },
                }}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)}),0),(_vm.showLine)?_c('div',{staticClass:"line"}):_vm._e(),(_vm.newsCity && _vm.newsCity.length)?_c('ul',{staticClass:"_items_list news__city-list"},_vm._l((_vm.newsCity),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"_item__header"},[(item.category && item.category.title)?_c('a',{staticClass:"_item__category news__city__color",attrs:{"href":"#"}},[_vm._v("\n                  "+_vm._s(item.category.title)+"\n                ")]):_vm._e(),(item.date_publication)?_c('div',{staticClass:"_item__time news__city__color"},[_c('time',{attrs:{"datetime":_vm._f("robotDate")(item.date_publication)}},[_vm._v("\n                    "+_vm._s(_vm._f("humanDateRu")(item.date_publication)))])]):_vm._e()]),_c('router-link',{staticClass:"_item__text",attrs:{"to":{
                  name: 'news_item',
                  params: { id: item.id, url: item.link },
                }}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)}),0):_vm._e()]):_vm._e(),(_vm.messages && _vm.messages.length)?_c('div',{staticClass:"col-3 news__side"},[_c('h2',[_vm._v("Сообщения")]),_c('ul',{staticClass:"_items_list news__messages-list"},_vm._l((_vm.messages),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"messages__item__header _item__header"},[(item.category && item.category.title)?_c('a',{staticClass:"messages__item__category _item__category messages__color",attrs:{"href":"#"}},[_vm._v("\n                  "+_vm._s(item.category.title)+"\n                ")]):_vm._e(),(item.date_publication)?_c('div',{staticClass:"messages__item__time _item__time messages__color"},[_c('time',{attrs:{"datetime":_vm._f("robotDate")(item.date_publication)}},[_vm._v("\n                    "+_vm._s(_vm._f("humanDateRu")(item.date_publication))+"\n                  ")])]):_vm._e()]),_c('router-link',{staticClass:"messages__item__text _item__text",attrs:{"to":{
                  name: 'messages_item',
                  params: { id: item.id, url: item.link },
                }}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)}),0)]):_vm._e()]),(_vm.loading)?_c('a',{staticClass:"show__more__link",attrs:{"href":"#"}},[_c('LoadingIndicator',{attrs:{"title":"Загрузка"}})],1):(_vm.showLoadMore)?_c('a',{staticClass:"show__more__link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.loadMore.apply(null, arguments)}}},[_vm._v("\n        Показать ещё\n      ")]):_vm._e()])]),(_vm.newsRegion && _vm.newsRegion.length)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"news__inner"},[_c('div',{staticClass:"news__side"},[_c('h2',[_vm._v("Новости региона")]),_c('ul',{staticClass:"_items_list news__region-list"},_vm._l((_vm.newsRegion),function(item,index){return _c('li',{key:index,staticClass:"_item"},[_c('div',{staticClass:"img"},[_c('router-link',{staticClass:"img__container",attrs:{"to":{
                  name: 'news_item',
                  params: { id: item.id, url: item.link },
                }}},[_c('img',{attrs:{"src":_vm._f("image")(item.head_img,_vm.$store.state.api),"alt":_vm._f("image_alt")(item.head_img)}})])],1),_c('div',{staticClass:"news__region__header _item__header"},[(item.category && item.category.title)?_c('a',{staticClass:"_item__category news__region__color",attrs:{"href":"#"}},[_vm._v("\n                "+_vm._s(item.category.title)+"\n              ")]):_vm._e(),(item.date_publication)?_c('div',{staticClass:"_item__time news__region__color"},[_c('time',{attrs:{"datetime":_vm._f("robotDate")(item.date_publication)}},[_vm._v("\n                  "+_vm._s(_vm._f("humanDateRu")(item.date_publication))+"\n                ")])]):_vm._e()]),_c('router-link',{staticClass:"_item__text",attrs:{"to":{
                name: 'news_item',
                params: { id: item.id, url: item.link },
              }}},[_vm._v("\n              "+_vm._s(item.title)+"\n            ")])],1)}),0),_c('a',{staticClass:"show__all-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.setTypeCode(_vm.$store.state.type.CODE_REGION_NEWS)}}},[_vm._v("\n          Все новости региона\n        ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }