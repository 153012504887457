<template>
  <div class="news news__page">
    <section class="section section__city__news">
      <div class="news__inner">
        <div class="row">
          <div class="col-9 news__side" v-if="showAside">
            <h2>Все новости города</h2>
            <ul class="_items_list news__city-list">
              <li class="_item" :key="index" v-for="(item, index) in newsMayor">
                <div class="img">
                  <router-link
                    :to="{
                      name: 'news_item',
                      params: { id: item.id, url: item.link },
                    }"
                    class="img__container"
                  >
                    <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
                  </router-link>
                </div>
                <div class="news__mayor__header _item__header">
                  <a
                    href="#"
                    class="_item__category news__mayor__color"
                    v-if="item.category && item.category.title"
                  >
                    {{ item.category.title }}
                  </a>
                  <div class="_item__time news__mayor__color" v-if="item.date_publication">
                    <time :datetime="item.date_publication | robotDate">
                      {{ item.date_publication | humanDateRu }}
                    </time>
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'news_item',
                    params: { id: item.id, url: item.link },
                  }"
                  class="_item__text"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
            <div v-if="showLine" class="line"></div>
            <ul class="_items_list news__city-list" v-if="newsCity && newsCity.length">
              <li :key="index" v-for="(item, index) in newsCity">
                <div class="_item__header">
                  <a
                    href="#"
                    class="_item__category news__city__color"
                    v-if="item.category && item.category.title"
                  >
                    {{ item.category.title }}
                  </a>
                  <div class="_item__time news__city__color" v-if="item.date_publication">
                    <time :datetime="item.date_publication | robotDate">
                      {{ item.date_publication | humanDateRu }}</time
                    >
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'news_item',
                    params: { id: item.id, url: item.link },
                  }"
                  class="_item__text"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-3 news__side" v-if="messages && messages.length">
            <h2>Сообщения</h2>
            <ul class="_items_list news__messages-list">
              <li :key="index" v-for="(item, index) in messages">
                <div class="messages__item__header _item__header">
                  <a
                    href="#"
                    class="messages__item__category _item__category messages__color"
                    v-if="item.category && item.category.title"
                  >
                    {{ item.category.title }}
                  </a>
                  <div class="messages__item__time _item__time messages__color" v-if="item.date_publication">
                    <time :datetime="item.date_publication | robotDate">
                      {{ item.date_publication | humanDateRu }}
                    </time>
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'messages_item',
                    params: { id: item.id, url: item.link },
                  }"
                  class="messages__item__text _item__text"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <a v-if="loading" href="#" class="show__more__link">
          <LoadingIndicator title="Загрузка" />
        </a>
        <a @click.prevent="loadMore" v-else-if="showLoadMore" href="#" class="show__more__link">
          Показать ещё
        </a>
      </div>
    </section>
    <section class="section" v-if="newsRegion && newsRegion.length">
      <div class="news__inner">
        <div class="news__side">
          <h2>Новости региона</h2>
          <ul class="_items_list news__region-list">
            <li class="_item" :key="index" v-for="(item, index) in newsRegion">
              <div class="img">
                <router-link
                  :to="{
                    name: 'news_item',
                    params: { id: item.id, url: item.link },
                  }"
                  class="img__container"
                >
                  <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
                </router-link>
              </div>
              <div class="news__region__header _item__header">
                <a
                  href="#"
                  class="_item__category news__region__color"
                  v-if="item.category && item.category.title"
                >
                  {{ item.category.title }}
                </a>
                <div class="_item__time news__region__color" v-if="item.date_publication">
                  <time :datetime="item.date_publication | robotDate">
                    {{ item.date_publication | humanDateRu }}
                  </time>
                </div>
              </div>
              <router-link
                :to="{
                  name: 'news_item',
                  params: { id: item.id, url: item.link },
                }"
                class="_item__text"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
          <a @click="setTypeCode($store.state.type.CODE_REGION_NEWS)" href="#" class="show__all-link">
            Все новости региона
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  name: "NewsComponent",
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    showLoadMore() {
      return (
        this.$store.state.news_page.news_city.next_page_url ||
        this.$store.state.news_page.messages.next_page_url
      );
    },
    showLine() {
      return this.newsMayor && this.newsMayor.length && this.newsCity && this.newsCity.length;
    },
    showAside() {
      return (this.newsMayor && this.newsMayor.length) || (this.newsCity && this.newsCity.length);
    },
    newsMayor() {
      return this.$store.state.news_page.news_mayor?.data;
    },
    newsCity() {
      return this.$store.state.news_page.news_city?.data;
    },
    messages() {
      return this.$store.state.news_page.messages?.data;
    },
    newsRegion() {
      return this.$store.state.news_page.news_region?.data;
    },
  },
  methods: {
    /**
     * Фильтр новостей по определенному типу
     * @param code
     */
    setTypeCode(code) {
      this.$store.state.newsTypeCode = code;
    },
    /**
     * Загрузка новых новостей
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        const newsPage = this.$store.state.news_page;
        let nextPageUrl;
        if (newsPage.news_city.next_page_url) {
          nextPageUrl = newsPage.news_city.next_page_url;
        } else if (newsPage.messages.next_page_url) {
          nextPageUrl = newsPage.messages.next_page_url;
        } else {
          return;
        }
        await axios
          .get(nextPageUrl)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые новости с новой страницы пагинации
            this.$store.state.news_page.news_city.data = this.$store.state.news_page.news_city.data.concat(
              data.news_city.data
            );
            this.$store.state.news_page.news_city.next_page_url = data.news_city.next_page_url;
            this.$store.state.news_page.messages.data = this.$store.state.news_page.messages.data.concat(
              data.messages.data
            );
            this.$store.state.news_page.messages.next_page_url = data.messages.next_page_url;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
  },
};
</script>
