<template>
  <section class="section news__filter__wrap filter__form-change-wrap">
    <form
      @submit.prevent="submitFilterForm"
      ref="filterForm"
      class="filter__form filter__form-change"
      autocomplete="off"
    >
      <div class="search">
        <div class="search__input">
          <label for="news_search" class="search__label"> Введите слово для поиска </label>
          <div class="search__input__wrap">
            <input v-model="form.title.value" id="news_search" type="text" maxlength="255" />
            <button v-if="form.title.value" type="button" @click="resetSearchInput">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div class="search__btn">
          <button v-if="loading" class="btn-blue_dark">
            <LoadingIndicator title="Загрузка" />
          </button>
          <button v-else type="submit" class="btn-blue_dark">Найти</button>
        </div>
      </div>
      <div class="filter">
        <div v-if="newsTypes" class="filter__item filter__select">
          <label>Тип новости</label>
          <Multiselect
            track-by="id"
            label="name"
            v-model="form.type_id.value"
            :options="newsTypes"
            placeholder="Все типы"
            selectLabel="Выбрать ↵"
            deselectLabel="Удалить ↵"
            selectedLabel="Выбрано"
            :searchable="false"
            :allow-empty="true"
          >
            <span slot="noOptions">Список пуст</span>
          </Multiselect>
        </div>
        <div v-if="sortedMainCategories" class="filter__item filter__select">
          <label>Сфера деятельности</label>
          <Multiselect
            track-by="id"
            label="title"
            v-model="form.category_id.value"
            :options="sortedMainCategories"
            placeholder="Все сферы"
            selectLabel="Выбрать ↵"
            deselectLabel="Удалить ↵"
            selectedLabel="Выбрано"
            :searchable="true"
            :allow-empty="true"
          >
            <div slot="option" slot-scope="item">
              <span :class="{ option__dedicated: item.option.child_categories }" class="option">
                {{ item.option.title }}
              </span>
            </div>
            <span slot="noOptions">Список пуст</span>
            <span slot="noResult">Ничего не найдено.</span>
          </Multiselect>
        </div>
        <div class="filter__item filter__date">
          <label>Диапазон дат</label>
          <div class="filter__date__inputs">
            <div class="filter__date__input">
              <flat-pickr v-model="form.start_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
            <div class="filter__date__input">
              <flat-pickr v-model="form.end_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
          </div>
        </div>
        <div class="filter__actions">
          <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
        </div>
      </div>
    </form>

    <a
      @click.prevent="changeFilterForm"
      class="filter__change-btn"
      :class="{ hide__btn: isFilterVisible }"
      href="#"
    >
      <span v-if="isFilterVisible">Скрыть фильтр</span>
      <span v-else>Поиск и фильтр по новостям</span>
      <ArrowDownIcon />
    </a>
  </section>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Multiselect from "vue-multiselect";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "NewsFilter",
  components: {
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
  data() {
    return {
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      loading: false,
      form: {
        type_id: {
          value: null,
          message: null,
        },
        title: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
      },
    };
  },
  /**
   * Если в state есть тип новости, то фильтровать только по данному типу
   */
  created() {
    if (this.$store.state.newsTypeCode) {
      this.allNewsByType();
    }
  },
  watch: {
    /**
     * Если изменится тип новости, то фильтровать только по данному типу
     */
    "$store.state.newsTypeCode"() {
      if (this.$store.state.newsTypeCode) {
        this.allNewsByType();
      }
    },
  },
  computed: {
    newsTypes() {
      let types = [];
      types.push(
        this.$store.state.news_types.find((item) => item.code === this.$store.state.type.CODE_MAYOR_NEWS)
      );
      types.push(
        this.$store.state.news_types.find((item) => item.code === this.$store.state.type.CODE_CITY_NEWS)
      );
      types.push(
        this.$store.state.news_types.find((item) => item.code === this.$store.state.type.CODE_REGION_NEWS)
      );
      types.push(
        this.$store.state.news_types.find((item) => item.code === this.$store.state.type.CODE_MESSAGES)
      );
      return types;
    },
    mainCategories() {
      if (this.$store.state.main_categories && this.$store.state.main_categories.length) {
        return this.$store.state.main_categories.filter((item) => {
          return item.status.code === this.$store.state.status.CODE_ACTIVE;
        });
      }
      return [];
    },
    sortedMainCategories() {
      let newCategories = [];
      this.mainCategories.forEach((item) => {
        newCategories.push(item);
        if (item.child_categories && item.child_categories.length) {
          newCategories = newCategories.concat(
            item.child_categories.filter(
              (child) => child.status.code === this.$store.state.status.CODE_ACTIVE
            )
          );
        }
      });
      return newCategories;
    },
  },
  methods: {
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      // console.log("filter.scrollHeight", filter.scrollHeight);
      // console.log("getBoundingClientRect", filter.getBoundingClientRect());
      // filter.style.maxHeight = this.isFilterVisible ? null : filter.scrollHeight + "px";
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    /**
     * Фильтр новостей только по определенному типу
     */
    allNewsByType() {
      this.form.type_id.value = this.$store.state.news_types.find(
        (item) => item.code === this.$store.state.newsTypeCode
      );
      this.submitFilterForm();
    },
    /**
     * Фильтр новостей
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "type_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.code);
          } else if (key === "category_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else {
            if (this.form[key].value !== null) {
              fd.append(key, this.form[key].value);
            }
          }
        });

        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_NEWS_FILTER", { data: fd })
          .then((response) => {
            this.loading = false;
            this.$store.state.filterResponse = response.data;
            this.$store.state.filterList = response.data.news.data;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
