<template>
  <div class="actual" v-if="actualNews && actualNews.length">
    <h2>Актуально</h2>
    <div class="actual__news-list _items_list">
      <div class="_item" :key="index" v-for="(item, index) in actualNews">
        <div class="img">
          <router-link
            :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
            class="img__container"
          >
            <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
          </router-link>
        </div>
        <div class="_item__header">
          <div>
            <a class="_item__category" v-if="item.category.title">{{ item.category.title }}</a>
          </div>
          <div class="_item__time" v-if="item.date_publication">
            <time :datetime="item.date_publication | robotDate">{{ item.date_publication | humanDateRu }}</time>
          </div>
        </div>
        <router-link
          :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
          class="_item__text text__cut"
        >
          {{ item.title }}
        </router-link>
      </div>
    </div>
    <router-link :to="{ name: 'news' }" class="actual__link">Все новости</router-link>
  </div>
</template>

<script>
export default {
  name: "ActualNewsComponent",
  computed: {
    actualNews() {
      return this.$store.state.news_actual;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"

.actual {
  &__news-list {
    display grid
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(768px) {
      .img {
        ratio(208, 161)
      }
    }
    +below(680px) {
      grid-template-columns repeat(2, 1fr)

      .img {
        ratio(360, 228)
      }
    }
    +below(480px) {
      grid-template-columns 1fr

      .img {
        ratio(288, 123)
      }
    }
  }

  &__link {
    font-size: 1em;
    line-height: 24px;
    text-decoration-line: underline;
    color: var(--main_color);
    margin auto 0 0 0
  }
}
</style>
