<template>
  <section class="section news__filter__results">
    <div class="open__row">
      <div class="filter__news__wrap">
        <ul class="filter__news__list" v-if="responseNews.data && responseNews.data.length">
          <li class="filter__news__item" v-for="(item, index) in $store.state.filterList" :key="index">
            <div class="filter__news__info">
              <div
                :class="getColor(item.type.code)"
                class="_item__category"
                v-if="item.category && item.category.title"
              >
                {{ item.category.title }}
              </div>
              <time
                :class="getColor(item.type.code)"
                class="_item__time"
                :time="item.date_publication | robotDate"
              >
                {{ item.date_publication | humanDateRu }}
              </time>
            </div>
            <div class="filter__news__title">
              <router-link
                :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
                class="_item__text"
              >
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ul>
        <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
          По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
        </p>
        <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
        <a v-if="loading" href="#" class="show-more-link">
          <LoadingIndicator title="Загрузка" />
        </a>
        <a
          v-else-if="responseNews.next_page_url"
          @click.prevent="submitFilterForm()"
          href="#"
          class="show-more-link"
        >
          Показать ещё
        </a>
      </div>
      <div class="open__col-aside">
        <ActualNewsComponent />
      </div>
    </div>
  </section>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ActualNewsComponent from "components/ActualNewsComponent.vue";

export default {
  name: "NewsFilterResults",
  components: {
    LoadingIndicator,
    ActualNewsComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    responseNews() {
      return this.$store.state.filterResponse?.news;
    },
    newsActual() {
      return this.$store.state.news_actual;
    },
  },
  methods: {
    submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("POST_NEWS_FILTER", {
            data: this.$store.state.filterData,
            url: this.responseNews.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            this.$store.state.filterList.push(...response.data.news.data);
            this.$store.state.filterResponse = response.data;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilter() {
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
      this.$store.state.newsTypeCode = null;
      window.scrollTo(0, 0);
    },
    getColor(code) {
      if (code === this.$store.state.type.CODE_MESSAGES) {
        return "messages__color";
      }
      if (code === this.$store.state.type.CODE_MAYOR_NEWS) {
        return "news__mayor__color";
      }
      if (code === this.$store.state.type.CODE_CITY_NEWS) {
        return "news__city__color";
      }
      if (code === this.$store.state.type.CODE_REGION_NEWS) {
        return "news__region__color";
      }
    },
  },
};
</script>
