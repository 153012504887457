<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <NewsFilter />
        <News v-if="!$store.state.filterResponse" />
        <FilterResults v-if="$store.state.filterResponse" />
      </div>
    </div>
  </main>
</template>

<script>
import NewsFilter from "@/views/news/components/NewsFilter.vue";
import News from "@/views/news/components/News.vue";
import FilterResults from "@/views/news/components/FilterResults.vue";

export default {
  name: "NewsComponent",
  async asyncData({ store }) {
    await store.dispatch("GET_NEWS_PAGE");
  },
  components: {
    NewsFilter,
    News,
    FilterResults,
  },
  beforeRouteLeave(to, from, next) {
    // if (to.name !== 'news_item') {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    this.$store.state.newsTypeCode = null;
    // }
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
<style lang="stylus">
@import "~@/styles/parts/news/index.styl"
</style>
